import React from "react";

const ParticipantsLabel = () => {
  return (
    <div className="participants_label_container">
      <p className="participants_label_paragraph">PARTICIPANTS</p>
    </div>
  );
};

export default ParticipantsLabel;
